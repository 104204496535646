import { useQuery, useQueryClient } from '@tanstack/react-query'
import { requestGET } from '../services/request'
import { useAuth0 } from '@auth0/auth0-react'

export const useUser = (enabled = false) => {
  const auth = useAuth0()
  return useQuery<any>({
    queryKey: [`user`],
    queryFn: async () => {
      return requestGET(`/user`, undefined, auth)
    },
    enabled,
  })
}
export const useM2MToken = () => {
  const queryClient = useQueryClient()
  const auth = useAuth0()
  return {
    getToken: () =>
      queryClient.fetchQuery({
        queryKey: [`adminToken`],
        queryFn: async () =>
          await requestGET(
            `organization/m2mTokenFromAdminToken`,
            undefined,
            auth,
          ),
      }),
  }
}
